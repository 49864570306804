<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>
          آمار بیشترین بازدید توسط مرورگرها
        </b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body class="browsers scroll-thin">

      <div
        v-for="(item,index) in browsersData"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-img
                style="width: 2rem;height: 2rem"
              :src="item.browserImg"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ item.browser }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ item.percent.toFixed(2) }} %</span>
          <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          />
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  props:{
    browsersData:{
      type:Array,
    }
  },
  data() {
    return {
      chartData: [],
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [
        {
          browserImg: require('@/assets/images/icons/google-chrome.png'),
          name: 'Google Chrome',
          usage: '54.4%',
        },
        {
          browserImg: require('@/assets/images/icons/mozila-firefox.png'),
          name: 'Mozila Firefox',
          usage: '6.1%',
        },
        {
          browserImg: require('@/assets/images/icons/apple-safari.png'),
          name: 'Apple Safari',
          usage: '14.6%',
        },
        {
          browserImg: require('@/assets/images/icons/internet-explorer.png'),
          name: 'Internet Explorer',
          usage: '4.2%',
        },
        {
          browserImg: require('@/assets/images/icons/opera.png'),
          name: 'Opera Mini',
          usage: '8.%',
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  created() {
    let allCounts = 0;
    this.browsersData.forEach(el => {
      allCounts += el.count;
    })

    this.browsersData.forEach(e => {
      e['percent'] = (e.count/allCounts)*100;
      if (e.browser.match('Firefox')){e['browserImg'] = require('@/assets/images/icons/mozila-firefox.png')}
      else if (e.browser.match('Chrome')){e['browserImg'] = require('@/assets/images/icons/google-chrome.png')}
      else if (e.browser.match('Safari')){e['browserImg'] = require('@/assets/images/icons/apple-safari.png')}
      else if (e.browser.match('Opera')){e['browserImg'] = require('@/assets/images/icons/opera.png')}
      else if (e.browser.match('Instagram')){e['browserImg'] = require('@/assets/images/icons/instagram.png')}
      else {e['browserImg'] = require('@/assets/images/icons/internet-explorer.png')}
    })

    for (let i = 0; i < this.browsersData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[Math.round(Math.random()*4)];
      chartClone.series[0] = this.browsersData[i].percent;
      this.chartData.push(chartClone)
    }
  },
}
</script>

<style >
.browsers{
  max-height: 30rem!important;
  overflow-y: auto;
}
.scroll-thin::-webkit-scrollbar {
  border-radius: 6px;
  background-color: transparent;
  width: 6px;
}
</style>
