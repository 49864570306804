<template>
  <b-card
      class="card-congratulation-medal"
  >
    <h5>تعداد بازدیدکنندگان آنلاین</h5>
    <b-card-text class="font-small-3">

    </b-card-text>
    <h1 class="mb-75 mt-2 pt-50">
      <b-avatar
          size="48"
          variant="primary"
      >
        <feather-icon
            size="24"
            icon="UsersIcon"
        />
      </b-avatar>
      <span class="font-large-2">
        {{ OnlineUsers }}
        نفر
      </span>
    </h1>
    <b-img
        :src="require('@/assets/images/illustration/badge.svg')"
        class="congratulation-medal"
        alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,BAvatar
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {kFormatter} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    BAvatar
  },
  directives: {
    Ripple,
  },
  props: {
    OnlineUsers: {
      type: Number,
      default: 0
    }
  },
  methods: {
    kFormatter,
  },
}
</script>
