import WebServiceRequest from '../Api/WebServiceRequest'

class ActivityGetDashboardRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Activity/GetDashboard')
    }
}

export {
    ActivityGetDashboardRequest,
}