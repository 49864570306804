<template>
  <b-card
    v-if="Data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>
        <h3 class="font-weight-bolder">
        آمار کلی بازدیدها
        </h3>
      </b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <b-dropdown
            :text="selectedDay.label"
            variant="flat-primary"
            dropup
            right
        >
          <b-dropdown-item
              v-for="day in lastDays"
              :key="day"
              @click="changeDay(day)"
          >
            {{ day.label }}
          </b-dropdown-item>
        </b-dropdown>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row v-if="Data">
        <b-col
            sm="6"
            class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-success"
              >
                <feather-icon
                    size="24"
                    icon="EyeIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                تعداد بازدیدها
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ selectedData.views }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="UsersIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                تعداد بازدیدکنندگان
              </h4>
              <b-card-text class="font-small-5 mb-0">
                {{ selectedData.viewers }}
                نفر
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown , BDropdownItem
} from 'bootstrap-vue'

export default {
  data(){
    return{
      lastDays:[
        { key: 1,label:'امروز'},
        { key: 2,label:'دیروز'},
        { key: 3,label:'هفتگی'},
        { key: 4,label:'ماهانه'},
        { key: 5,label:'سالانه'},
      ],
      selectedDay:{ key: 1,label:'امروز'},
      selectedData: {
        viewers:0,
        views:0,
      }
    }
  },
  mounted() {
    this.changeDay({ key: 1,label:'امروز'});
  },
  methods:{
    changeDay(data){
      let _this = this;

      _this.selectedDay = data;
      if(_this.selectedDay.key === 1){_this.selectedData.viewers = _this.Data.todaysIPCount ; _this.selectedData.views = _this.Data.todaysSiteViewsCount ;}
      if(_this.selectedDay.key === 2){_this.selectedData.viewers = _this.Data.yesterdaysIPCount ; _this.selectedData.views = _this.Data.yesterdaySiteViews ;}
      if(_this.selectedDay.key === 3){_this.selectedData.viewers = _this.Data.weeksIPCount ; _this.selectedData.views = _this.Data.weekSiteViews ;}
      if(_this.selectedDay.key === 4){_this.selectedData.viewers = _this.Data.monthsIPCount ; _this.selectedData.views = _this.Data.monthSiteViews ;}
      if(_this.selectedDay.key === 5){_this.selectedData.viewers = _this.Data.yearsIPCount ; _this.selectedData.views = _this.Data.yearSiteViews ;}
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BDropdown ,
    BDropdownItem,
  },
  props: {
    Data: {
      type: Object,
    },
  },
}
</script>
