<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>برترین ارجاع دهندگان</b-card-title>
    </b-card-header>

    <b-card-body class="referrer pt-1">
      <app-timeline>
       <app-timeline-item
          v-for="(tag , idx) in data"
          class="transaction-item my-0"
          :variant="setColors(idx)"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-success"
            >
              <feather-icon
                size="18"
                icon="AtSignIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              <a class="d-block" :href="tag.referer" target="_blank">{{ tag.referer }}</a>
            </h6>
            <small>
              <feather-icon
                  class="mr-1"
                  size="18"
                  icon="EyeIcon"
              />
              {{ tag.count }}
            </small>
          </b-media-body>
        </b-media>
      </app-timeline-item>
      </app-timeline>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";

export default {
  data(){
    return{
      colors:['primary','success','info','danger','warning','secondary','primary','success','info','danger','warning','secondary']
    }
  },
  methods:{
    setColors(val){
      return this.colors[Math.round(Math.random()*10)]
    },
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>
 .referrer{
   max-height: 35rem!important;
   overflow-y: auto;
 }
</style>
