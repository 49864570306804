<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

      <b-card style="min-height: 80vh" class="logo-card">
        <div class="bg-logo"></div>
        <h1 class="text-primary welcome-text">به پنل ادمین مکس  خوش آمدید.</h1>
      </b-card>

<!--    <section v-if="analyticsData" id="dashboard-ecommerce">-->
<!--      <b-row class="match-height">-->
<!--        <b-col-->
<!--            md="3"-->
<!--        >-->
<!--          <ecommerce-medal :OnlineUsers="OnlineUsers"/>-->
<!--        </b-col>-->
<!--        <b-col-->
<!--            md="2"-->
<!--        >-->
<!--          <ecommerce-order-chart :data="data.statisticsOrder" :count="analyticsData.totalIncome"/>-->
<!--        </b-col>-->
<!--        <b-col-->
<!--            md="7"-->
<!--        >-->
<!--          <ecommerce-statistics :Data="analyticsData"/>-->
<!--        </b-col>-->
<!--      </b-row>-->

<!--      <b-row class="match-height">-->

<!--        <b-col cols="12">-->
<!--          <apex-data-bar-chart :chartData="analyticsData.userWeekBar"/>-->
<!--        </b-col>-->

<!--        &lt;!&ndash; Browser States Card &ndash;&gt;-->
<!--        <b-col-->
<!--            xl="6"-->
<!--        >-->
<!--          <ecommerce-browser-states :browsersData="analyticsData.mostBrowser"/>-->
<!--        </b-col>-->
<!--        &lt;!&ndash;/ Browser States Card &ndash;&gt;-->

<!--        &lt;!&ndash; Goal Overview Card &ndash;&gt;-->
<!--&lt;!&ndash;        <b-col&ndash;&gt;-->
<!--&lt;!&ndash;            xl="5"&ndash;&gt;-->
<!--&lt;!&ndash;        >&ndash;&gt;-->
<!--&lt;!&ndash;          <OsStates :OSData="analyticsData.mostOS"/>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;          <ecommerce-earnings-chart :OSData="analyticsData.mostOS" :data="data.earningsChart"/>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        </b-col>&ndash;&gt;-->
<!--        &lt;!&ndash;/ Goal Overview Card &ndash;&gt;-->

<!--        &lt;!&ndash; Transaction Card &ndash;&gt;-->
<!--        <b-col-->
<!--            xl="6"-->
<!--        >-->
<!--          <ecommerce-transactions :data="analyticsData.mostReferer"/>-->
<!--        </b-col>-->
<!--        &lt;!&ndash;/ Transaction Card &ndash;&gt;-->

<!--        &lt;!&ndash; Tables Card &ndash;&gt;-->
<!--        <b-col lg="6">-->
<!--          <ecommerce-courses-table :data="analyticsData.coursesViews"/>-->
<!--        </b-col>-->

<!--        <b-col lg="6">-->
<!--          <ecommerce-blogs-table :data="analyticsData.blogsViews"/>-->
<!--        </b-col>-->

<!--        &lt;!&ndash;/ Tables Card &ndash;&gt;-->
<!--      </b-row>-->
<!--    </section>-->
  </b-overlay>
</template>

<script>
import {BRow, BCol, BCard , BOverlay} from 'bootstrap-vue'

import {getUserData} from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCoursesTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'
import {ActivityGetDashboardRequest} from "@/libs/Api/Activity";
import {mapGetters} from "vuex";
import OsStates from "@/views/dashboard/ecommerce/OsStates";
import EcommerceCoursesTable from "@/views/dashboard/ecommerce/EcommerceCoursesTable";
import EcommercePodcastsTable from "@/views/dashboard/ecommerce/EcommercePodcastsTable";
import EcommerceLivesTable from "@/views/dashboard/ecommerce/EcommerceLivesTable";
import EcommerceEducationalPostTable from "@/views/dashboard/ecommerce/EcommerceEducationalPostTable";
import EcommerceFilesTable from "@/views/dashboard/ecommerce/EcommerceFilesTable";
import EcommerceBlogsTable from "@/views/dashboard/ecommerce/EcommerceBlogsTable";
import ApexDataBarChart from "@/views/dashboard/ecommerce/ApexDataBarChart";

export default {
  title: " پنل ادمین مکس ",
  components: {
    ApexDataBarChart,
    EcommerceBlogsTable,
    EcommerceFilesTable,
    EcommerceEducationalPostTable,
    EcommerceLivesTable,
    EcommercePodcastsTable,
    EcommerceCoursesTable,
    OsStates,
    BRow,
    BCol,
    BCard,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    BOverlay
  },
  data() {
    return {
      data: {},
      showOverlay: false,
      analyticsData: null,
    }
  },
  computed:{
    // ...mapGetters(["OnlineUsers"])
  },
  async created() {
    // await this.getDashboardData();
    // // data
    // this.$http.get('/ecommerce/data')
    //     .then(response => {
    //       this.data = response.data
    //
    //       // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //       // ? This is just for demo purpose
    //       const userData = getUserData()
    //       // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    //     })
  },
  methods: {
    async getDashboardData() {
      let _this = this;
      _this.showOverlay = true;

      let activityGetDashboardRequest = new ActivityGetDashboardRequest(_this);
      await activityGetDashboardRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.analyticsData = content;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error);
      })
    },
  },
}
</script>

<style lang="scss">
.logo-card {
  display: flex;
  flex-flow: column;
  justify-items: center;
  justify-content: center;
  align-items: center;

}

.bg-logo {
  margin-left: auto;
  margin-right: auto;
  width: 40rem;
  height: 40rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("../../../../src/assets/images/logo/logo.png");
}

.welcome-text {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 900;
}

@media only screen and (max-width: 576px) {
  * {
    font-size: 1rem !important;
  }
  .bg-logo {
    width: 20rem;
    height: 20rem;
  }
}

@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
