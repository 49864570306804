<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        جرئیات آمار بازدید ها
      </b-card-title>
      <!--/ title and legend -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
          style="direction: ltr!important;"
          type="bar"
          height="400"
          :options="columnChart.chartOptions"
          :series="columnChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import apexChatData from './apexChartData'

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
}
export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,
  },
  props: {
    chartData: {
      type: Object,
    }
  },
  created() {
    this.columnChart.series[0].data.push(this.chartData.oneDayBeforeViewCount);
    this.columnChart.series[0].data.push(this.chartData.twoDayBeforeViewCount);
    this.columnChart.series[0].data.push(this.chartData.threeDayBeforeViewCount);
    this.columnChart.series[0].data.push(this.chartData.fourDayBeforeViewCount);
    this.columnChart.series[0].data.push(this.chartData.fiveDayBeforeViewCount);
    this.columnChart.series[0].data.push(this.chartData.sixDayBeforeViewCount);
    this.columnChart.series[0].data.push(this.chartData.sevenDayBeforeViewCount);

    this.columnChart.series[1].data.push(this.chartData.oneDayBeforeIPCount);
    this.columnChart.series[1].data.push(this.chartData.twoDayBeforeIPCount);
    this.columnChart.series[1].data.push(this.chartData.threeDayBeforeIPCount);
    this.columnChart.series[1].data.push(this.chartData.fourDayBeforeIPCount);
    this.columnChart.series[1].data.push(this.chartData.fiveDayBeforeIPCount);
    this.columnChart.series[1].data.push(this.chartData.sixDayBeforeIPCount);
    this.columnChart.series[1].data.push(this.chartData.sevenDayBeforeIPCount);
  },
  data() {
    return {
      columnChart: {
        series: [
          {
            name: 'بازدید‌ها',
            data: [],
          },
          {
            name: 'بازدیدکنندگان',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [chartColors.column.series1, chartColors.column.series2],
          plotOptions: {
            bar: {
              columnWidth: '15%',
              colors: {
                backgroundBarColors: [
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                  chartColors.column.bg,
                ],
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: ['دیروز', 'پریروز', 'سه روز پیش', 'چهار روز پیش', 'پنج روز پیش', 'شش روز پیش', 'هفت روز پیش'],
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      },
    }
  },
}
</script>
