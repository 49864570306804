<template>
  <b-card
      v-if="data"
      no-body
      class="card-company-table max-h"
  >
    <div class="m-1">
      <b-media-aside>
        <b-avatar
            rounded
            size="42"
            variant="light-primary"
        >
          <feather-icon
              size="18"
              icon="BookIcon"
          />
        </b-avatar>
      </b-media-aside>
      <h3 class="font-weight-bolder d-inline-block ml-1 text-primary">پربازدیدترین دوره ها</h3>
    </div>
    <b-table
        :items="data"
        responsive
        :fields="fields"
        class="my-0"
        show-empty
        empty-text="موردی موجود نیست!"
    >
      <!-- productTitle -->
      <template #cell(productTitle)="data">
        <div class="d-flex align-items-start">
          <b-avatar
              size="50"
              rounded="circle"
              :src="baseURL+'media/gallery/product/'+data.item.pictureUrl"
          >
          </b-avatar>
          <div>
            <div class="font-weight-bolder ml-1">
              {{ data.item.productTitle }}
            </div>
            <div class="font-small-2 text-muted ml-1">
              {{ data.item.productId }} @
            </div>
          </div>
        </div>
      </template>

      <!-- viewCount -->
      <template #cell(viewCount)="data">
        <div class="d-flex align-items-center">
          <b-avatar
              class="mr-1 p-0"
              variant="light-primary"
          >
            <feather-icon
                size="14"
                icon="EyeIcon"
            />
          </b-avatar>
          <span>{{ data.item.viewCount }}</span>
        </div>
      </template>

      <!-- viewCount -->
      <template #cell(commentsCount)="data">
        <div class="d-flex align-items-center">
          <b-avatar
              class="mr-1 p-0"
              variant="light-success"
          >
            <feather-icon
                size="14"
                icon="MessageCircleIcon"
            />
          </b-avatar>
          <span>{{ data.item.commentsCount }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(view)="data">
        <b-link :to="{ name: 'apps-e-commerce-product-details', params: { id: data.item.productId } }">
          <feather-icon icon="EditIcon" variant="light-success" size="20"/>
        </b-link>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BLink
} from 'bootstrap-vue'
import Helper from "@/libs/Helper";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BLink
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      baseURL:Helper.baseUrl,
      fields: [
        {key: 'productTitle', label: 'عنوان'},
        {key: 'viewCount', label: 'تعداد بازدید ها'},
        {key: 'commentsCount', label: 'تعداد کامنت ها'},
        {key: 'view', label: 'نمایش'},
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.max-h{
  max-height: 40rem!important;
  overflow-y: auto;
}
.scroll-thin::-webkit-scrollbar {
  border-radius: 6px;
  background-color: transparent;
  width: 6px;
}
</style>
