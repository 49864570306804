<template>
  <b-card
      v-if="OSData"
      class="earnings-card"
  >
    <b-card-header>
      <div>
        <b-card-title>
          آمار بیشترین بازدید توسط سیستم عامل ها
        </b-card-title>
      </div>
    </b-card-header>
    <b-row class="mt-2">
      <b-col cols="12">
        <!-- chart -->
        <vue-apex-charts
            :options="chartOptions"
            :series="series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    OSData:{
      type:Array,
    }
  },
  created() {
    let allCounts = 0;
    this.OSData.forEach(el => {
      allCounts += el.count;
    })

    this.OSData.forEach(e => {
      e['percent'] = Math.ceil((e.count/allCounts)*100);
    })

    for (let i = 0; i < this.OSData.length; i += 1) {
      this.series.push(this.OSData[i].percent);
      this.chartOptions.labels.push(this.OSData[i].os);
      // this.chartOptions.options.colors[0] = this.chartOptions[Math.round(Math.random())];
      // this.chartData.push(chartClone)
    }
    this.chartOptions.plotOptions.pie.donut.labels.total.label = this.OSData[0].os;
    // this.chartOptions.plotOptions.pie.donut.labels.total.formatter(this.OSData[0].percent)
  },
  data() {
    return {
      series:[],
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger , $themeColors.success],
      chartOptions: {
        tooltip:{
          enabled: true,
          formatter(val){
            return `% ${parseInt(val)}`
          }
        },
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        comparedResult: [2, -3, 8],
        labels: [],
        stroke: {width: 0},
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger , $themeColors.success],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            startAngle: -10,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `% ${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: '',
                  formatter: function (val) {
                    // return `% ${parseInt(val)}`
                    return "% "+ val.config.series[0]
                  },
                },
              },
            },
          },
        },
        // responsive: [
        //   {
        //     breakpoint: 1325,
        //     options: {
        //       chart: {
        //         height: 100,
        //       },
        //     },
        //   },
        //   {
        //     breakpoint: 1200,
        //     options: {
        //       chart: {
        //         height: 120,
        //       },
        //     },
        //   },
        //   {
        //     breakpoint: 1045,
        //     options: {
        //       chart: {
        //         height: 100,
        //       },
        //     },
        //   },
        //   {
        //     breakpoint: 992,
        //     options: {
        //       chart: {
        //         height: 120,
        //       },
        //     },
        //   },
        // ],
      },
    }
  },
}
</script>